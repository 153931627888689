<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Correctly identify each of the pieces of glassware/equipment in the image below:
      </p>

      <p class="pl-4 mb-8">
        <v-img style="max-width: 623px" :src="imageName" />
      </p>

      <v-select
        v-for="imgLabel in imageLabels"
        v-model="inputs[imgLabel.value]"
        :items="items"
        item-text="text"
        item-value="value"
        :key="`option${imgLabel.value}`"
        :label="imgLabel.text"
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Chem1LD_LFQ_Question2',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        labelA: null,
        labelB: null,
        labelC: null,
        labelD: null,
        labelE: null,
        labelF: null,
      },
      items: [
        {text: 'Clamp', value: 'clamp'},
        {text: 'Disposable pipet', value: 'disposablePipet'},
        {text: 'Graduated cylinder', value: 'gradCylinder'},
        {text: 'Powder funnel', value: 'powderFunnel'},
        {text: 'Stir bar', value: 'stirBar'},
        {text: 'Watch glass', value: 'watchGlass'},
      ],
      imageLabels: [
        {text: 'A:', value: 'labelA'},
        {text: 'B:', value: 'labelB'},
        {text: 'C:', value: 'labelC'},
        {text: 'D:', value: 'labelD'},
        {text: 'E:', value: 'labelE'},
        {text: 'F:', value: 'labelF'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/UCI_1LD_LFQ_Q2.jpg';
    },
  },
};
</script>
<style scoped></style>
